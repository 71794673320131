<style lang="scss">
  .networks-page {}
</style>
<!--eslint-disable-->
<template>
  <div class="networks-page">
    <b-card class="" title="Network List">
      <b-card-text>This page shows all networks in your enterprise. You can create and update networks directly from this page.</b-card-text>
    </b-card>

    <b-card class="" no-body>
      <div class="row p-2" v-if="$can('Create', 'Network')">
        <div class="col-12">
          <b-button @click="$bvModal.show('modal-add-network')" variant="primary">Create Network</b-button>
        </div>
      </div>
      <b-table
        class="data-table"
        hover
        empty-text="No Networks available"
        :items="networks"
        :fields="headers">
        <template #cell(name)="data">
          <router-link :to="{ name: 'network-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap text-underline">
            {{data.item.name}}
          </router-link>
        </template>
        <template #cell(actions)="data">
          <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Network')"
                                    @click="$router.push({name: 'network-edit', params: {uid: data.item.uid}})">
              Update
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Network')"
                                    @click="showNetworkModal(data.item, 'modal-delete-network')">
              Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <b-modal id="modal-add-network" title="Add new network" size="lg" hide-footer>
      <add-network @refresh="refreshNetworks()" @close="closeModals()"></add-network>
    </b-modal>

    <b-modal id="modal-delete-network" title="Delete network" hide-footer>
      <delete-network-model
        v-if="selectedNetwork"
        :network="selectedNetwork"
        @close="closeModals"
        @deleted="refreshNetworks"
      />
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import DeleteNetworkModel from '@/views/networks/sections/DeleteNetworkModel.vue';
  import NetworkService from '../../services/NetworkService';
  import AddNetwork from './sections/AddNetwork.vue';

  export default {
    name: 'Networks',
    components: {
      DeleteNetworkModel,
      AddNetwork,
    },
    props: {},
    data() {
      return {
        selectedNetwork: null,
        networks: [],
        headers: [
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'security',
            label: 'Security',
          },
          {
            key: 'ssid',
            label: 'SSID',
          },
          {
            key: 'auto_connect',
            label: 'Auto Connect',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
      };
    },
    mounted() {
      this.getNetworks();
    },
    methods: {
      refreshNetworks() {
        this.closeModals();
        this.getNetworks();
      },
      closeModals() {
        this.$bvModal.hide('modal-add-network');
        this.$bvModal.hide('modal-update-network');
        this.$bvModal.hide('modal-delete-network');
      },
      getNetworks() {
        NetworkService.getNetworks(this.filters).then(res => {
          this.networks = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get networks, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      setSelectedNetwork(network) {
        this.selectedNetwork = network;
      },
      showNetworkModal(network, modalName) {
        this.setSelectedNetwork(network);
        this.$bvModal.show(modalName);
      },
      deleteNetwork(network) {
        NetworkService.deleteNetwork(network.uid).then(() => {
          this.$toast.success(`Deleted network ${network.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshNetworks();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete network, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
    },
  };
</script>
